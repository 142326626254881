import {Text} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC} from 'react';
import CoverageDiscoverMoreCardFA from './CoverageDiscoverMoreCardFA';
import CoverageDiscoverMoreCardPA from './CoverageDiscoverMoreCardPA';
import {useCoverageRestrictionsContext, useUserAgentContext} from '@/context';
export interface ICoverageDiscoverMoreProps {
  className?: ClassValue;
  hasRestrictions: boolean;
  hasPA: boolean;
  onPdp?: boolean;
}

const CoverageDiscoverMore: FC<ICoverageDiscoverMoreProps> = ({
  className,
  hasRestrictions,
  hasPA,
  onPdp = false,
}) => {
  const {isMobileOrTablet} = useUserAgentContext();
  const {result, showFinancialEnrollment} = useCoverageRestrictionsContext();
  const showFinancialFull =
    !result?.formularyId || (!hasRestrictions && !hasPA);
  const showNextSteps = hasPA || hasRestrictions || showFinancialEnrollment;

  /** Styles */
  const fullCardStyle = 'h-auto w-full';
  const halfCardStyle = 'md:h-auto md:!min-w-1/2';
  const PAStyles = clsx(
    !showFinancialEnrollment ? fullCardStyle : halfCardStyle,
  );
  const FAStyles = clsx(showFinancialFull ? fullCardStyle : halfCardStyle);

  if (!showNextSteps) {
    return null;
  }

  return (
    <div className={clsx('flex flex-col space-y-3', className)}>
      <Text as={isMobileOrTablet ? 'title-md' : 'title-lg'} weight="extrabold">
        Discover More
      </Text>

      <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 justify-center">
        {/** We need to add new buttons after the first demo */}
        {hasRestrictions || hasPA ? (
          <CoverageDiscoverMoreCardPA
            onPdp={onPdp}
            withPA={hasPA}
            className={PAStyles}
          />
        ) : null}
        {showFinancialEnrollment ? (
          <CoverageDiscoverMoreCardFA onPdp={onPdp} className={FAStyles} />
        ) : null}
      </div>
    </div>
  );
};
export default CoverageDiscoverMore;
