import {customEvent} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useEffect, useMemo, useRef} from 'react';
import {useCookies} from 'react-cookie';
import CoverageBody from './CoverageBody';
import McoQuickPicks from './CoverageBody/CoverageSummarySearch/CoveragePayerForm/McoQuckPicks';
import CoverageModalReload from './CoverageModalReload';
import {
  CEVENT_COVERAGE_RESULTS_NEW_NPI,
  CEVENT_COVERAGE_RESULTS_REPEAT_NPI,
  CEVENT_COVERAGE_RESULTS_WITHOUT_NPI,
} from '@/constants';
import {NPI_COOKIE} from '@/constants/cookies';
import {COVERAGE_V3} from '@/constants/flags';
import {useCoverageRestrictionsContext, useFlag} from '@/context';
import {useCurrentDrug} from '@/hooks';
import CoverageHeader from '@/modules/Coverage/CoverageHeader';
interface ICoverageProps {
  onPdp?: boolean;
  highlighted?: boolean;
}

// When coverage_v3 will be on in prod, we need to delete the old version
const Coverage: FC<ICoverageProps> = ({onPdp = false, highlighted = false}) => {
  const showCoverageV3 = useFlag(COVERAGE_V3);
  const {drugName} = useCurrentDrug();
  const {
    result,
    reset,
    generalData,
    alreadyHadNPIValue,
    showForm,
    showEmployerForm,
  } = useCoverageRestrictionsContext();
  const {currentStep} = generalData;
  const [cookies, _] = useCookies([NPI_COOKIE]);
  const npi = useMemo(() => cookies?.npi, [cookies]);

  const prevDrugNameRef = useRef<string | null>(null);

  const showCoverageHeader = currentStep === 1 && !(result || showEmployerForm);

  useEffect(() => {
    if (drugName !== prevDrugNameRef.current) {
      prevDrugNameRef.current = drugName;
      reset();
    }
  }, [drugName, reset]);

  const restrictions = useMemo(
    () => result?.carrier?.paRestrictions ?? [],
    [result],
  );

  const stepsTheraphy = useMemo(() => result?.carrier?.steps ?? [], [result]);

  const hasSteps = useMemo(() => {
    if (stepsTheraphy.length > 0) {
      return (
        stepsTheraphy[0].therapies[0].toLowerCase() !== 'not covered' &&
        stepsTheraphy[0].therapies[0].toLowerCase() !== 'no step'
      );
    }

    return false;
  }, [stepsTheraphy]);

  useEffect(() => {
    const getEventName = () => {
      if (npi) {
        if (alreadyHadNPIValue) {
          return CEVENT_COVERAGE_RESULTS_REPEAT_NPI;
        } else {
          return CEVENT_COVERAGE_RESULTS_NEW_NPI;
        }
      } else {
        return CEVENT_COVERAGE_RESULTS_WITHOUT_NPI;
      }
    };

    if (result) {
      const eventName = getEventName();
      customEvent(eventName, {
        employer: result?.employer ?? '',
        mco: result?.payerName,
        channel: result?.channel,
        planType: result?.planType,
        coverageRestrictions: {
          count: restrictions.length,
          text: JSON.stringify(restrictions),
        },
        stepTherapies: {
          count: hasSteps ? stepsTheraphy.length : 0,
          text: hasSteps ? JSON.stringify(stepsTheraphy) : '',
        },
      });
    }
  }, [result, restrictions, stepsTheraphy, npi, hasSteps, alreadyHadNPIValue]);

  return (
    <div
      className={clsx('mx-auto flex w-full max-w-full flex-col', {
        'pb-10 md:px-0 md:pb-12': !onPdp,
        'relative md:px-[133px]': !onPdp && showCoverageV3,
        'after:mx-auto after:mt-10 after:h-[1px] after:w-full after:max-w-[1360px] after:bg-gray-200 md:mb-10 md:after:content-[""]':
          highlighted,
      })}>
      <div
        className={clsx('mx-auto flex w-full flex-col md:w-[618px]', {
          'md:w-[982px]': result,
          'md:w-full': onPdp,
        })}>
        <div
          className={clsx({
            'mb-5 md:mt-12': !onPdp,
            'md:mt-0': onPdp,
            'mb-5': onPdp && !showForm,
          })}>
          {!onPdp && showCoverageHeader ? (
            <div className='mb-5'>
              <CoverageHeader drugName={drugName} />
            </div>
          ) : null}
          <CoverageBody onPdp={onPdp} />
        </div>
      </div>
      <McoQuickPicks highlighted={highlighted} onPdp={onPdp} />

      <CoverageModalReload onConfirm={reset} />
    </div>
  );
};
export default Coverage;
