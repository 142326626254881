import clsx, {ClassValue} from 'clsx';
import {FC, useMemo} from 'react';
import CtaSection from './CtaSection';
import HighlightSection from './HighlightSection';
import {HighlightSectionType} from './HighlightSection/models';
import {PdpHeader} from '@/components';
export interface IHeaderProps {
  samples?: boolean;
  coverage?: boolean;
  interactions?: boolean;
  financialAssistance?: boolean;
  priorAuthorization?: boolean;
  patientEducation?: boolean;
  className?: ClassValue;
}

const Header: FC<IHeaderProps> = ({
  samples = false,
  coverage = false,
  interactions = false,
  financialAssistance = false,
  priorAuthorization = false,
  patientEducation = false,
  className,
}) => {
  const sectionOrder = useMemo(
    () => [
      {name: 'samples', status: samples},
      {name: 'coverage', status: coverage},
      {name: 'interactions', status: interactions},
      {name: 'financialAssistance', status: financialAssistance},
      {name: 'priorAuthorization', status: priorAuthorization},
      {name: 'patientEducation', status: patientEducation},
    ],
    [
      coverage,
      financialAssistance,
      interactions,
      patientEducation,
      priorAuthorization,
      samples,
    ],
  );
  const highlightSection = useMemo((): HighlightSectionType | undefined => {
    const firstOpAvailable = sectionOrder.find((section) => section.status);
    return firstOpAvailable
      ? (firstOpAvailable.name as HighlightSectionType)
      : undefined;
  }, [sectionOrder]);

  return (
    <div
      id='pdp_header'
      data-testid='pdp-header'
      className={clsx(
        'flex w-full flex-col items-center justify-center',
        className,
      )}>
      <div
        className={clsx(
          'flex w-full flex-col items-center justify-center',
          'space-y-6 bg-green-100 px-6 pb-6 pt-5 md:pb-0',
          'md:mb-10 md:space-y-5 md:bg-pdp-header',
        )}>
        <PdpHeader />
        {highlightSection ? (
          <HighlightSection section={highlightSection} />
        ) : null}
      </div>
      <CtaSection
        coverage={coverage && highlightSection !== 'coverage'}
        interactions={interactions && highlightSection !== 'interactions'}
        financialAssistance={
          financialAssistance && highlightSection !== 'financialAssistance'
        }
        priorAuthorization={
          priorAuthorization && highlightSection !== 'priorAuthorization'
        }
        patientEducation={
          patientEducation && highlightSection !== 'patientEducation'
        }
        className='px-6 pt-6 md:px-[140px] md:pt-0 md:max-w-[1440px] mx-auto'
      />
    </div>
  );
};

export default Header;
