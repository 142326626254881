import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import {Text, Loader, Button} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {X} from 'lucide-react';
import React, {
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import Icon from '../Icon';
import MobileComboBox from '../MobileComboBox';
import {useCoverageRestrictionsContext, useUserAgentContext} from '@/context';

export interface IComboBoxOption {
  id: number;
  value: string;
}

export type ComboBoxSize = 'md' | 'lg' | 'xl';

export interface IComboBoxProps {
  placeholder?: string;
  selectedItem?: IComboBoxOption | null;
  onChange?: (value: IComboBoxOption | null) => void;
  onQueryChange?: (query: string) => void;
  options: IComboBoxOption[];
  isLoading?: boolean;
  className?: ClassValue;
  noMatchText?: string;
  showNoMatchText?: boolean;
  disabled?: boolean;
  enableFiltering?: boolean;
  inputContainerClassName?: ClassValue;
  openOnFocus?: boolean;
  showCancelSearch?: boolean;
  clearOnNoMatch?: boolean;
  onCancelClick?: () => void;
  onInputBlur?: () => void;
  onComboboxClose?: () => void;
  onFocus?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  dropdownClassName?: ClassValue;
  iconClassName?: ClassValue;
  size?: ComboBoxSize;
  showIconOnFocus?: boolean;
  cellClassName?: ClassValue;
  containerClassName?: ClassValue;
  id?: string;
  optionsHeader?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  showLabel?: boolean;
  isHighlitedCoverage?: boolean;
  onPdp?: boolean;
}

const CoverageComboBox: FC<IComboBoxProps> = ({
  selectedItem,
  onChange,
  onQueryChange,
  options,
  placeholder,
  className,
  isLoading = false,
  noMatchText = 'No Options',
  showNoMatchText = true,
  enableFiltering = true, // this is to filter options based on the current query (client-side), use false when the options are already filtered on the server
  clearOnNoMatch = false,
  disabled = false,
  openOnFocus = false,
  showCancelSearch = true,
  onInputBlur,
  onComboboxClose,
  onCancelClick,
  inputContainerClassName,
  onFocus,
  onKeyDown,
  dropdownClassName,
  iconClassName,
  size = 'lg',
  showIconOnFocus = false,
  cellClassName,
  containerClassName,
  id,
  optionsHeader,
  inputRef,
  showLabel = false,
  isHighlitedCoverage = false,
  onPdp = false,
}) => {
  const {query, setQuery} = useCoverageRestrictionsContext();
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const [showCoverageBtn, setShowCoverageBtn] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const {isMobileOrTablet} = useUserAgentContext();
  const _inputRef = useRef<HTMLInputElement>(null);
  const cboxInputRef = inputRef ?? _inputRef;

  useEffect(() => {
    const mocTopPicksCondition =
      query.length === 0 && selectedItem && query !== selectedItem.value;

    if (
      mocTopPicksCondition || // When user clicks an option from the MCO top picks
      inputFocus || // To ensure that the check coverage button is only shown when input is out of focus
      (selectedItem && query === selectedItem.value && query.length > 0) // When user clicks on "Try another search" after checking for coverage
    ) {
      if (mocTopPicksCondition) {
        // Only set query to selected item value when user clicks on MCO top picks
        setQuery(selectedItem.value);
      }
      setShowCoverageBtn(false);
    }

    if (!inputFocus && !selectedItem && query.length === 0) {
      setShowCoverageBtn(true);
    }
  }, [query, selectedItem, setQuery, inputFocus]);

  const filteredOptions = useMemo(() => {
    if (enableFiltering) {
      return query === ''
        ? options
        : options.filter((item: IComboBoxOption) =>
            item.value.toLowerCase().includes(query.toLowerCase()),
          );
    } else {
      return options;
    }
  }, [query, options, enableFiltering]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    onQueryChange?.(event.target.value);

    // If user types or deletes after selecting an option, we reset the selection
    if (
      query === selectedItem?.value &&
      event.target.value !== selectedItem?.value
    ) {
      onChange?.(null);
    }
  };

  const handleMobileQueryChange = (_query: string) => {
    setQuery(_query);
    onQueryChange?.(_query);
  };

  const handleCoverageClick = () => {
    if (cboxInputRef?.current) {
      cboxInputRef?.current.focus();
    }

    if (isMobileOrTablet) {
      setShowMobileSearch(true);
    }
  };

  const handleSelect = (value: IComboBoxOption) => {
    onChange?.(value);
    setQuery(value.value);
  };

  const handleMobileSelect = (value: IComboBoxOption) => {
    setShowMobileSearch(false);
    if (value) {
      setShowCoverageBtn(false);
    }
    handleSelect(value);
  };

  const handleInputBlur = () => {
    setInputFocus(false);
    if (!selectedItem && query.length === 0) {
      setShowCoverageBtn(true);
    }
    onInputBlur?.();
  };

  const handleMobileBackClick = () => {
    setShowMobileSearch(false);
    if (!selectedItem && query.length === 0) {
      setShowCoverageBtn(true);
    }
  };

  const handleComboBoxClose = useCallback(() => {
    // avoids query to be reset when combobox closes and theres no option selected
    if (!clearOnNoMatch && !selectedItem && filteredOptions.length === 0) {
      setQuery((currentQuery: string) => currentQuery);
    }
    onComboboxClose?.();
  }, [
    clearOnNoMatch,
    selectedItem,
    filteredOptions,
    onComboboxClose,
    setQuery,
  ]);

  const clearSearch = useCallback(() => {
    onChange?.(null);
    setQuery('');
    onQueryChange?.('');
    onCancelClick?.();
    setShowCoverageBtn(true);
  }, [onCancelClick, onChange, onQueryChange, setQuery]);

  const handleInputClick = () => {
    if (isMobileOrTablet) {
      setShowMobileSearch(true);
    }
  };

  const showLeftIcon = (!inputFocus || showIconOnFocus) && !selectedItem;

  const renderLeftIcon = useCallback(() => {
    if (!showLeftIcon) return null;

    const IconWrapper = isMobileOrTablet ? 'div' : ComboboxButton;

    return (
      <IconWrapper
        as='div'
        className='absolute left-3 top-0 flex h-full cursor-text items-center'>
        <Icon
          name='search'
          size={20}
          className={clsx(
            {
              'text-neutral-primary': !disabled,
              'text-neutral-secondary': disabled,
            },
            iconClassName,
          )}
        />
      </IconWrapper>
    );
  }, [showLeftIcon, iconClassName, disabled, isMobileOrTablet]);

  const renderCancelButton = useCallback(() => {
    if (!selectedItem || !showCancelSearch) return null;

    return (
      <button
        disabled={disabled}
        type='button'
        onClick={() => clearSearch()}
        data-testid='cancel-search-btn'
        className='cursor-pointer bg-transparent pr-3'>
        <X size={20} className='text-neutral-secondary' />
      </button>
    );
  }, [selectedItem, showCancelSearch, disabled, clearSearch]);

  const renderComboboxOptions = useCallback(() => {
    if (isLoading) return <Loader />;

    if (filteredOptions.length === 0 && query !== '' && !isLoading) {
      return (
        <div className='flex h-12 items-center justify-start rounded-sm bg-white p-3'>
          <Text as='body-sm' weight='bold' variant='tertiary'>
            {noMatchText}
          </Text>
        </div>
      );
    }

    return filteredOptions.map((item) => (
      <ComboboxOption
        key={item.id}
        value={item}
        className={clsx(
          'group relative flex h-14 cursor-pointer select-none items-center bg-white px-4 py-3 text-neutral-primary data-[focus]:bg-neutral-lighter data-[selected]:bg-neutral-lighter',
          cellClassName,
        )}>
        <Text
          className='group-hover:text- block truncate'
          as='body-md'
          size='md'
          weight='semibold'>
          {item.value}
        </Text>
      </ComboboxOption>
    ));
  }, [isLoading, filteredOptions, query, noMatchText, cellClassName]);

  const coverageButtonSize = isHighlitedCoverage ? 'lg' : 'xl';

  return (
    <>
      <div
        className={clsx('flex flex-col gap-y-1', containerClassName, {
          'md:max-w-[433px]':
            !inputFocus && !selectedItem && isHighlitedCoverage && !onPdp,
        })}>
        {showLabel ? (
          <Text as='body-sm' weight='bold'>
            Insurance Carrier
          </Text>
        ) : null}
        <Combobox
          as='div'
          value={selectedItem}
          immediate={openOnFocus}
          onChange={handleSelect}
          className={clsx('relative', className)}
          disabled={disabled}
          onFocus={onFocus}
          onClose={handleComboBoxClose}>
          <div
            ref={containerRef}
            className={clsx(
              'group relative flex items-center rounded-lg border border-solid',
              inputContainerClassName,
              {
                'border-neutral-tertiary-alt bg-white': !disabled,
                'hover:border-neutral-secondary': !disabled && !inputFocus,
                'border-neutral-light': disabled,
                'border-neutral-primary': inputFocus && !disabled,
              },
            )}>
            <button className='flex-1' onClick={handleInputClick}>
              {renderLeftIcon()}
              <ComboboxInput
                ref={cboxInputRef}
                id={id}
                data-testid={id}
                data-clarity-unmask='true'
                onKeyDown={onKeyDown}
                onClick={() => {
                  if (isMobileOrTablet) {
                    cboxInputRef?.current?.blur();
                  }
                }}
                className={clsx(
                  'w-full truncate rounded-lg border-none font-default text-base font-semibold focus:outline-none disabled:bg-white sm:disabled:placeholder:text-neutral-tertiary',
                  {
                    'h-10 p-2': size === 'md',
                    'py-3 md:h-[46px]': size === 'lg',
                    'h-14 py-4 pr-6 leading-[26px]': size === 'xl',
                    'pl-11': showLeftIcon,
                    'pl-3': !showLeftIcon,
                    'cursor-not-allowed bg-neutral-light text-neutral-tertiary':
                      disabled && !isMobileOrTablet,
                    'text-neutral-primary focus:border-neutral-primary':
                      !disabled,
                    'placeholder:text-neutral-secondary': inputFocus,
                    'placeholder:text-neutral-tertiary': !inputFocus,
                    'placeholder: text-base md:text-sm': isHighlitedCoverage,
                  },
                )}
                onChange={handleInputChange}
                value={query}
                onFocus={() => {
                  setInputFocus(true);
                  setShowCoverageBtn(false);
                }}
                autoComplete='off'
                onBlur={handleInputBlur}
                placeholder={placeholder}
              />
            </button>
            {renderCancelButton()}
          </div>
          <ComboboxOptions
            anchor='bottom start'
            portal={false}
            transition
            className={clsx(
              'origin-top transition duration-200 ease-out empty:invisible data-[closed]:scale-95 data-[closed]:opacity-0',
              'notes-scrollbar mt-[1px] max-h-60 w-[var(--input-width)] overflow-auto rounded-lg bg-white text-base focus:outline-none sm:text-sm',
              {
                hidden: !showNoMatchText && filteredOptions.length === 0,
              },
              dropdownClassName,
            )}>
            {optionsHeader ? (
              <div className='border-0 border-b border-solid border-neutral-lighter px-4 py-2'>
                <Text as='title-md' weight='extrabold'>
                  {optionsHeader}
                </Text>
              </div>
            ) : null}
            {renderComboboxOptions()}
          </ComboboxOptions>
        </Combobox>
      </div>
      {showCoverageBtn ? (
        <Button
          id='search_check_coverage'
          stretched
          size={coverageButtonSize}
          onClick={handleCoverageClick}
          className='md:max-w-[169px]'>
          Check Now
        </Button>
      ) : null}
      <MobileComboBox
        isOpen={showMobileSearch}
        options={filteredOptions}
        onSelectItem={handleMobileSelect}
        query={query}
        onQueryChange={handleMobileQueryChange}
        placeholder={placeholder}
        onClearClick={clearSearch}
        onBackClick={handleMobileBackClick}
      />
    </>
  );
};

export default CoverageComboBox;
