import {ClassValue} from 'clsx';
import {FC} from 'react';
import CoverageDiscoverMoreCard from './CoverageDiscoverMoreCard';
import {CEVENT_COVERAGE_NEXT_FINANCIAL} from '@/constants';
import { DISCOVER_MORE_FA_IMAGE_URL } from '@/constants/discoverMoreCard';
import {useCoverageRestrictionsContext} from '@/context';
import {useCurrentDrug} from '@/hooks';
import {customEvent} from '@/utils/gtm';

export interface ICoverageDiscoverMoreProps {
  className?: ClassValue;
  onPdp?: boolean;
}

const CoverageDiscoverMoreCardFA: FC<ICoverageDiscoverMoreProps> = ({
  className,
  onPdp = false,
}) => {
  const {slug} = useCurrentDrug();
  const {setIsAssistanceProgramsModalOpen} = useCoverageRestrictionsContext();

  const handleFinancialAssistanceCard = () => {
    if (slug) {
      customEvent(CEVENT_COVERAGE_NEXT_FINANCIAL);
      if (onPdp) {
        setIsAssistanceProgramsModalOpen(true);
      } else {
        const url = '/therapies/' + slug + '#financial_assistance';
        window.open(`${window.location.origin}/${url}`, '_blank');
      }
    }
  };

  return (
    <CoverageDiscoverMoreCard
      testId='mock-coverage-fa'
      title='Financial Assistance'
      description=''
      cta='Get Enrollment Forms'
      urlImage={DISCOVER_MORE_FA_IMAGE_URL}
      altImage='Financial Assistance'
      onClick={handleFinancialAssistanceCard}
      className={className}
    />
  );
};
export default CoverageDiscoverMoreCardFA;
